//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDateformat } from '@/util/getDate';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      id: '',
      info: {},
      goods_info: [],
      logisticsInfo: '',
      showMessage: !1,
      showOrderSend: !1,
      express_id: '', //快递公司
      tracking_no: '', //快递单号
      company_name: '',
      tracking_no1: '',
      orderSendType: 1,
      progress_status1: 0,
      progress_status2: 0,
      progress_status3: 0,
      progress_status4: 0,
    };
  },
  computed: {
    ...mapState({
      logisticsList: state => state.order.logisticsList, //物流公司列表
    }),
  },
  watch: {
    showOrderSend(val) {
      if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
    },
  },
  created() {
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.query.page);
    utils.rows = Number(this.$route.query.rows);
    utils.is_record = !0;
    this.id = this.$route.query.id;
    this.getOrderInfo();
    this.logisticsQuery();
  },
  methods: {
    handleSales(val) {
      this.handle_state = val;
      this.showHandleSales = !0;
    },
    comfirmSetSystemMessage() {
      this.$axios
        .post(this.$api.order.setSystemMessage, {
          id: this.id,
          system_message: this.info.system_message,
        })
        .then(res => {
          if (res.code == 0) {
            this.showMessage = !1;
            this.$message({
              message: '备注成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //发货
    confirmSend() {
      let orderInfo = this.info.extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
      }
      if (!this.tracking_no) this.$message.warning('请填写快递单号');
      let data = {
        tracking_no: this.tracking_no,
        id: this.id,
        express_id: this.express_id,
      };
      if (this.orderSendType) data.need_express = 1;
      let url = this.orderSendType ? this.$api.order.setSend : this.$api.order.editTracking;
      this.$axios.post(url, data).then(res => {
        if (res.code == 0) {
          this.getOrderInfo();
          this.logisticsQuery();
          this.showOrderSend = !1;
          this.$message.success(this.orderSendType ? '发货成功' : '修改成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //确认收货
    confirmReceipt() {
      let orderInfo = this.info.extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可确认收货！');
      }
      this.$confirm(`是否确认强制收货（谨慎使用！）`, '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.order.confirmReceipt, { id: this.id }).then(res => {
          if (res.code == 0) {
            this.getOrderInfo();
            this.$message({
              message: '订单收货成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //打印
    print() {
      let routeData = this.$router.resolve({
        path: '/print',
        query: {
          id: this.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    logisticsQuery() {
      this.$axios.post(this.$api.order.logisticsQuery, { id: this.id }).then(res => {
        if (res.code == 0 && res.result.info.returnCode != '400') {
          this.company_name = res.result.company_name;
          this.logisticsInfo = res.result.info;
          this.tracking_no1 = res.result.info.nu;
        } else if (res.code == -2) {
          this.tracking_no1 = res.result.tracking_no;
          this.company_name = res.result.company_name;
        }
      });
    },
    getOrderInfo() {
      this.$axios.post(this.$api.order.info, { id: this.id }).then(res => {
        if (res.code == 0) {
          let info = res.result.info;
          let stateArr = [10];
          if (info.payment_time) stateArr.push(20);
          if (info.cancel_time) stateArr.push(0);
          if (info.extend_order_extend.tracking_time) stateArr.push(30);
          if (info.finnshed_time) stateArr.push(40);
          if (info.evaluate_time) stateArr.push(50);
          if ([0, 10].sort().toString() == stateArr.sort().toString()) this.progress_status1 = 1;
          if ([0, 10, 20].sort().toString() == stateArr.sort().toString()) this.progress_status2 = 1;
          if ([0, 10, 20, 30].sort().toString() == stateArr.sort().toString()) this.progress_status3 = 1;
          if ([0, 10, 20, 30, 40].sort().toString() == stateArr.sort().toString()) this.progress_status4 = 1;
          this.info = info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
